import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledVisuals = styled.div`
  margin: 50px 0;
`;
const ImageWrapper = styled.div``;

const HouseVisuals: FC<{
  currentFloor: number;
  isButtonActive: number;
  data: any;
  whichHousesAreEqualToVis: any;
}> = ({ currentFloor, isButtonActive, data, whichHousesAreEqualToVis }) => (
  <>
    <StyledVisuals>
      {isButtonActive === 1 ? (
        currentFloor === 1 ? (
          <ImageWrapper>
            {whichHousesAreEqualToVis ? (
              <GatsbyImage
                image={data.allFile.nodes[0].childImageSharp.gatsbyImageData}
                alt="Ekowkra"
              />
            ) : (
              <GatsbyImage
                image={data.allFile.nodes[3].childImageSharp.gatsbyImageData}
                alt="Ekowkra"
              />
            )}
          </ImageWrapper>
        ) : currentFloor === 2 ? (
          <ImageWrapper>
            {whichHousesAreEqualToVis ? (
              <GatsbyImage
                image={data.allFile.nodes[1].childImageSharp.gatsbyImageData}
                alt="Ekowkra"
              />
            ) : (
              <GatsbyImage
                image={data.allFile.nodes[4].childImageSharp.gatsbyImageData}
                alt="Ekowkra"
              />
            )}
          </ImageWrapper>
        ) : (
          <ImageWrapper>
            {whichHousesAreEqualToVis ? (
              <GatsbyImage
                image={data.allFile.nodes[2].childImageSharp.gatsbyImageData}
                alt="Ekowkra"
              />
            ) : (
              <GatsbyImage
                image={data.allFile.nodes[5].childImageSharp.gatsbyImageData}
                alt="Ekowkra"
              />
            )}
          </ImageWrapper>
        )
      ) : currentFloor === 1 ? (
        <ImageWrapper>
          {whichHousesAreEqualToVis ? (
            <GatsbyImage
              image={data.allFile.nodes[6].childImageSharp.gatsbyImageData}
              alt="Ekowkra"
            />
          ) : (
            <GatsbyImage
              image={data.allFile.nodes[9].childImageSharp.gatsbyImageData}
              alt="Ekowkra"
            />
          )}
        </ImageWrapper>
      ) : currentFloor === 2 ? (
        <ImageWrapper>
          {whichHousesAreEqualToVis ? (
            <GatsbyImage
              image={data.allFile.nodes[7].childImageSharp.gatsbyImageData}
              alt="Ekowkra"
            />
          ) : (
            <GatsbyImage
              image={data.allFile.nodes[10].childImageSharp.gatsbyImageData}
              alt="Ekowkra"
            />
          )}
        </ImageWrapper>
      ) : (
        <ImageWrapper>
          {whichHousesAreEqualToVis ? (
            <GatsbyImage
              image={data.allFile.nodes[8].childImageSharp.gatsbyImageData}
              alt="Ekowkra"
            />
          ) : (
            <GatsbyImage
              image={data.allFile.nodes[11].childImageSharp.gatsbyImageData}
              alt="Ekowkra"
            />
          )}
        </ImageWrapper>
      )}
    </StyledVisuals>
  </>
);

export default HouseVisuals;
