import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import Button from '@components/atoms/Button/Button';
import scrollTo from '@utils/scrollTo';
import TopRightSvg from '@assets/svg/offer_top_left.svg';

interface StyledProps {
  $isPrice?: boolean;
}

const SectionWrapper = styled.section`
  position: relative;
  max-width: 1250px;
  margin: 0 auto;
  @media (min-width: 1025px) {
    padding: 100px 0 300px 0;
  }
`;

const SectionContainer = styled.section`
  max-width: 1250px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  padding: 50px 20px;
  position: relative;

  @media (min-width: 1025px) {
    padding: 50px 100px;
  }
`;

const HeadlineWrapper = styled.div`
  border-bottom: 5px solid ${({ theme }) => theme.lightGrey};
  padding-bottom: 15px;
`;

const StyledHeadline = styled(Headline)`
  font-weight: ${({ theme }) => theme.regular};
  text-align: center;
`;
const Calculator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 50px;
  }
`;

const RangeInput = styled.div`
  margin: 50px 0;
  width: 100%;
  input {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.darkBlue};
    position: relative;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #fff;
      border: 2px solid ${({ theme }) => theme.primary};
      cursor: pointer;
    }
    &::-ms-fill-lower {
      background: ${({ theme }) => theme.primary};
    }
    &::-moz-range-thumb {
      -moz-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #fff;
      border: 2px solid ${({ theme }) => theme.primary};
      cursor: pointer;
    }
    &::-moz-range-progress {
      background: ${({ theme }) => theme.primary};
    }
    &::-ms-thumb {
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #fff;
      border: 2px solid ${({ theme }) => theme.primary};
      cursor: pointer;
    }
    &::-ms-fill-lower {
      background: ${({ theme }) => theme.primary};
    }
  }
`;
const RangeInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const CalcResult = styled.div`
  background-color: ${({ theme }) => theme.lightGrey};
  border-radius: 20px;
  width: 80%;
  text-align: center;
  padding: 20px 10px;

  @media (min-width: 1025px) {
    width: 30%;
  }
`;

const StyledParagraph = styled(Paragraph)<StyledProps>`
  color: ${({ theme }) => theme.primary};
  margin-bottom: 20px;

  ${({ $isPrice }) =>
    $isPrice &&
    css`
      color: ${({ theme }) => theme.darkBlue};
      font-size: ${({ theme }) => theme.headingS};
      padding-bottom: 15px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        height: 5px;
        background-color: ${({ theme }) => theme.grey};
      }
    `}
`;

const InputsContainer = styled.div`
  width: 100%;
  @media (min-width: 1025px) {
    width: 50%;
  }
`;

const RangeParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.headingXXS};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: ${({ theme }) => theme.bodyS};
    margin-left: 10px;
  }
`;

const StyledBgLine = styled.span`
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.grey};
  transform: translate(-50%, 50%);
  z-index: -1;
`;

const StyledBgLineLeft = styled(StyledBgLine)`
  left: 20%;
  @media (min-width: 1025px) {
    left: 35%;
  }
`;
const StyledBgLineRight = styled(StyledBgLine)`
  left: 80%;

  @media (min-width: 1025px) {
    left: 65%;
  }
`;

const TopRightSvgWrapper = styled.img`
  display: none;
  @media (min-width: 576px) {
    display: block;
    position: absolute;
    width: 100px;
    top: 0;
    right: 0;
    transform: translate(10%, -50%);
  }

  @media (min-width: 1025px) {
    width: 120px;
  }
  @media (min-width: 1440px) {
    width: 150px;
    transform: translate(30%, -40%);
  }
`;

const LoanCalculator: FC<{ housePrice: number }> = ({ housePrice }) => {
  const [loanValue, setLoanValue] = useState<number>(100000);
  const [loanLenght, setLoanLenght] = useState<number>(5);
  const [loanInterest, setLoanInterest] = useState<number>(1.6);

  const minOwnValue = (10 / 100) * loanValue;
  const maxOwnValue = (40 / 100) * loanValue;
  const [loanOwnValue, setLoanOwnValue] = useState<number>(minOwnValue);

  const handleRangeChange = (e) => {
    setLoanValue(e);
  };

  const handleLoanLenghtChange = (e) => {
    setLoanLenght(e);
  };

  const handleLoanInterestChange = (e) => {
    setLoanInterest(e);
  };

  const handleLoanOwnValueChange = (e) => {
    setLoanOwnValue(e);
  };

  const handleScrollableLink = (e: any, target: string) => {
    e.preventDefault();
    scrollTo(target);
  };

  const maxLoanValue = housePrice;
  const yearsToMonths = loanLenght * 12;
  const interestCalc = (loanInterest / 100) * loanValue;
  const result = (loanValue - loanOwnValue + interestCalc) / yearsToMonths;

  return (
    <SectionWrapper>
      <SectionContainer>
        <HeadlineWrapper>
          <StyledHeadline
            as="h2"
            $isHeadline2
            $isMainColor
            data-aos="fade-down"
          >
            Oblicz swój <b>kredyt hipoteczny</b> w naszym kalkulatorze
          </StyledHeadline>
        </HeadlineWrapper>
        <Calculator>
          <InputsContainer>
            <RangeInput>
              <RangeInfo>
                <Paragraph>Kwota kredytu:</Paragraph>
                <RangeParagraph>
                  <b>{loanValue}</b>
                  <span>PLN</span>
                </RangeParagraph>
              </RangeInfo>
              <input
                type="range"
                name="quantity"
                min="100000"
                max={maxLoanValue}
                step="1000"
                defaultValue="100000"
                onChange={(e) => handleRangeChange(e.target.value)}
              />
            </RangeInput>
            <RangeInput>
              <RangeInfo>
                <Paragraph>Okres spłaty:</Paragraph>
                <RangeParagraph>
                  <b>{loanLenght}</b>
                  <span>Lat</span>
                </RangeParagraph>
              </RangeInfo>
              <input
                type="range"
                name="quantity"
                min="5"
                max="30"
                step="1"
                defaultValue="5"
                onChange={(e) => handleLoanLenghtChange(e.target.value)}
              />
            </RangeInput>
            <RangeInput>
              <RangeInfo>
                <Paragraph>Oprocentowanie:</Paragraph>
                <RangeParagraph>
                  <b>{loanInterest}</b>
                  <span>%</span>
                </RangeParagraph>
              </RangeInfo>
              <input
                type="range"
                name="quantity"
                min="1.6"
                max="1.9"
                step="0.1"
                defaultValue="1.6"
                onChange={(e) => handleLoanInterestChange(e.target.value)}
              />
            </RangeInput>
            <RangeInput>
              <RangeInfo>
                <Paragraph>Wkład własny:</Paragraph>
                <RangeParagraph>
                  <b>{loanOwnValue}</b>
                  <span>PLN</span>
                </RangeParagraph>
              </RangeInfo>
              <input
                type="range"
                name="quantity"
                min={minOwnValue}
                max={maxOwnValue}
                step="1000"
                defaultValue={minOwnValue}
                onChange={(e) => handleLoanOwnValueChange(e.target.value)}
              />
            </RangeInput>
          </InputsContainer>

          <CalcResult>
            <StyledParagraph>
              Szacunkowa <b>miesięczna</b> rata
            </StyledParagraph>
            <StyledParagraph $isPrice>
              <b>{result.toFixed(2)}</b> PLN
            </StyledParagraph>
            <Button>
              <a
                href="/#contact"
                onClick={(e) => {
                  handleScrollableLink(e, 'contact');
                }}
              >
                Skontaktuj się
              </a>
            </Button>
          </CalcResult>
        </Calculator>
        <TopRightSvgWrapper src={TopRightSvg} alt="" />
      </SectionContainer>
      <StyledBgLine></StyledBgLine>
      <StyledBgLineLeft></StyledBgLineLeft>
      <StyledBgLineRight></StyledBgLineRight>
    </SectionWrapper>
  );
};

export default LoanCalculator;
