import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import BasicTemplate from '@templates/BasicTemplate';
import scrollTo from '@utils/scrollTo';
import Headline from '@components/atoms/Headline/Headline';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import HouseVisuals from '@components/molecules/HouseVisuals/HouseVisuals';
import HouseSecondList from '@components/molecules/HouseSecondList/HouseSecondList';
import Visualisation from '@components/organisms/Visualisation/Visualisation';
import Contact from '@components/organisms/Contact/Contact';
import LoanCalculator from '@components/organisms/LoanCalculator/LoanCalculator';
import BackArrowSvg from '@assets/svg/back_arrow.svg';
import DownloadSvg from '@assets/svg/download.svg';
import { scroller } from 'react-scroll';

const SectionWrapper = styled.section`
  margin: 150px auto 100px auto;
  padding: 0 15px;

  @media (min-width: 576px) {
    max-width: 576px;
  }
  @media (min-width: 1025px) {
    max-width: 1250px;
  }
`;

const StyledTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 50px 0;
  padding-bottom: 25px;
  border-bottom: 5px solid ${({ theme }) => theme.grey};
`;
const StyledBack = styled.button`
  width: 130px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
    font-weight: ${({ theme }) => theme.bold};
    font-family: ${({ theme }) => theme.fontFamily};
    img {
      width: auto;
      height: auto;
      margin-right: 15px;
    }
  }
`;

const StyledHouseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1025px) {
    flex-direction: row-reverse;
  }
`;
const StyledHouseInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1025px) {
    width: 40%;
    padding-left: 50px;
  }
`;
const StyledHeadline = styled(Headline)`
  text-align: left;
  position: relative;
  margin-bottom: 30px;

  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 70px;
    height: 5px;
    background-color: ${({ theme }) => theme.grey};
  }
`;
const StyledStatus = styled(Paragraph)`
  font-size: ${({ theme }) => theme.headingXXS};
  margin-bottom: 30px;
`;

const StatusSpan = styled.span`
  font-weight: ${({ theme }) => theme.bold};

  &.dostepny {
    color: #95fabc;
  }
  &.zarezerwowany {
    color: #e6b43e;
  }
  &.zakupiony {
    color: #ff7777;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const StyledListItem = styled.li`
  border-bottom: 2px solid ${({ theme }) => theme.grey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  &:last-child {
    border: none;
  }
`;

const ListParagraph = styled.p`
  text-align: right;
  &.isBold {
    font-weight: ${({ theme }) => theme.bold};
  }
`;

const StyledPrice = styled.div`
  margin: 10px 0;
`;
const StyledPriceValue = styled.p`
  font-size: ${({ theme }) => theme.headingS};
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.primary};
`;

const StyledPriceValueSpan = styled.span`
  font-size: ${({ theme }) => theme.bodyM};
`;

const StyledHouseImages = styled.div`
  margin: 50px 0;
  @media (min-width: 1025px) {
    width: 60%;
    margin: 0;
  }
`;

const TopButtons = styled.div`
  display: flex;
  justify-content: center;
`;

const SwitchButton = styled.button<{ isRight?: boolean }>`
  cursor: pointer;
  background-color: ${({ theme }) => theme.secondary};
  width: 140px;
  height: 50px;
  outline: none;
  border: none;
  border-radius: 10px;
  margin-left: ${(props) => (props.isRight ? '30px' : '0')};

  &.btn--active {
    background-color: ${({ theme }) => theme.btnDarkBlue};
    box-shadow: 0 10px 20px rgba(49, 72, 98, 0.3);
  }
`;

const SwitchText = styled.p`
  color: #fff;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.medium};
  font-size: ${({ theme }) => theme.bodyL};
`;

const HouseTemplate = ({ data }) => {
  const [isButtonActive, setButtonActive] = useState<number>(1);
  const [currentFloor, setCurrentFloor] = useState<number>(1);
  let defaultWidth;

  if (typeof window !== `undefined`) {
    defaultWidth = window.innerWidth;
  }

  const useWindowSize = () => {
    const [width, setWidth] = useState({
      width: defaultWidth,
    });

    useEffect(() => {
      const handleResize = () =>
        setWidth({
          width: window.innerWidth,
        });

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
  };

  const { width } = useWindowSize();
  const house = data.datoCmsLokale;
  const whichHousesAreEqualToVis =
    house.propertyNumber === 1 ||
    house.propertyNumber === 3 ||
    house.propertyNumber === 5 ||
    house.propertyNumber === 7;

  const handleScrollableLink = (e: any, target: string) => {
    e.preventDefault();
    scrollTo(target);
  };

  const handleGoBackBtn = (e: any, target: string) => {
    e.preventDefault();
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(target, {
        duration: 1500,
        smooth: 'easeOutQuad',
        offset: -100,
      });
    }, 500);
  };

  return (
    <BasicTemplate title={`Lokal ${house.propertyNumber}`}>
      <SectionWrapper>
        <StyledTopBar>
          <StyledBack>
            <a
              href="/#houses"
              onClick={(e) => {
                handleGoBackBtn(e, 'houses');
              }}
            >
              <img src={BackArrowSvg} alt="" />
              Powrót
            </a>
          </StyledBack>
          <StyledBack>
            <a href={`/Lokal${house.propertyNumber}_PDF.pdf`} target="blank">
              <img src={DownloadSvg} alt="" />
              Pobierz PDF
            </a>
          </StyledBack>
        </StyledTopBar>
        <StyledHouseWrapper>
          <StyledHouseInfo data-aos="fade-up" data-aos-delay="1300">
            <StyledHeadline as="h2" $isMainColor $isHeadline2>
              {house.name}
            </StyledHeadline>
            <StyledStatus>
              Status:{' '}
              <StatusSpan className={house.availability}>
                {house.availability === 'dostepny'
                  ? 'dostępny'
                  : house.availability}
              </StatusSpan>
            </StyledStatus>
            <StyledList>
              <StyledListItem>
                <ListParagraph>Powierzchnia mieszkalna:</ListParagraph>
                <ListParagraph className="isBold">{`${house.area} m2`}</ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Powierzchnia działki:</ListParagraph>
                <ListParagraph className="isBold">{`${house.parcelArea} m2`}</ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Wspólna działka rekreacyjna:</ListParagraph>
                <ListParagraph className="isBold">{`${house.recreationPlot} m2`}</ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Całkowita ilość pomieszczeń:</ListParagraph>
                <ListParagraph className="isBold">
                  {house.roomsNumber}
                </ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Garaż:</ListParagraph>
                <ListParagraph className="isBold">{house.garage}</ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Termin oddania:</ListParagraph>
                <ListParagraph className="isBold">
                  {house.deliveryDate}
                </ListParagraph>
              </StyledListItem>
            </StyledList>
            <StyledPrice>
              <Paragraph>Cena:</Paragraph>
              <StyledPriceValue>
                {`${house.price} zł `}
                <StyledPriceValueSpan>(brutto)</StyledPriceValueSpan>
              </StyledPriceValue>
            </StyledPrice>

            {width >= 1025 && (
              <HouseSecondList currentFloor={currentFloor} house={house} />
            )}
          </StyledHouseInfo>
          <StyledHouseImages data-aos="fade-up" data-aos-delay="1000">
            <TopButtons>
              <SwitchButton
                onClick={() => setButtonActive(1)}
                className={isButtonActive === 1 ? 'btn--active' : ''}
              >
                <SwitchText>Wizualizacja</SwitchText>
              </SwitchButton>
              <SwitchButton
                isRight
                onClick={() => setButtonActive(2)}
                className={isButtonActive === 2 ? 'btn--active' : ''}
              >
                <SwitchText>Rzut 2D</SwitchText>
              </SwitchButton>
            </TopButtons>
            <HouseVisuals
              currentFloor={currentFloor}
              isButtonActive={isButtonActive}
              data={data}
              whichHousesAreEqualToVis={whichHousesAreEqualToVis}
            />
            <TopButtons>
              <SwitchButton
                onClick={() => setCurrentFloor(1)}
                className={currentFloor === 1 ? 'btn--active' : ''}
              >
                <SwitchText>Parter</SwitchText>
              </SwitchButton>
              <SwitchButton
                isRight
                onClick={() => setCurrentFloor(2)}
                className={currentFloor === 2 ? 'btn--active' : ''}
              >
                <SwitchText>Piętro</SwitchText>
              </SwitchButton>
              <SwitchButton
                isRight
                onClick={() => setCurrentFloor(3)}
                className={currentFloor === 3 ? 'btn--active' : ''}
              >
                <SwitchText>Poddasze</SwitchText>
              </SwitchButton>
            </TopButtons>
          </StyledHouseImages>
          {width <= 1025 && (
            <HouseSecondList currentFloor={currentFloor} house={house} />
          )}
        </StyledHouseWrapper>
      </SectionWrapper>
      <LoanCalculator housePrice={house.price} />
      <Visualisation />
      <Contact />
    </BasicTemplate>
  );
};

export const query = graphql`
  query HouseTemplate($propertyNumber: Int!) {
    datoCmsLokale(propertyNumber: { eq: $propertyNumber }) {
      propertyNumber
      price
      availability
      name
      area
      parcelArea
      recreationPlot
      garage
      vestibule
      stairs
      deliveryDate
      livingRoom
      garageArea
      toilet
      roomsNumber
      hall
      firstFloorHall
      firstFloorToilet
      firstFloorRoom
      firstFloorRoom2
      firstFloorLaundry
      firstFloorRoom3
      firstFloorStairs
      loftArea
      loftStairs
      totalFirstFloor
      totalFloor
      totalLoft
      roomsFloor
      roomsFirstFloor
      roomsLoft
    }
    allFile(
      filter: {
        name: {
          regex: "/1a_property1_floor|1b_property1_firstfloor|1c_property1_loft|2a_property2_floor|2b_property2_firstfloor|2c_property2_loft|3a_2dview_floor|3b_2dview_firstfloor|3c_2dview_loft|4a_2dview_floor|4b_2dview_firstfloor|4c_2dview_loft/"
        }
      }
      sort: { fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export default HouseTemplate;
