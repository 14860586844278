import React, { FC } from 'react';
import styled from 'styled-components';

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const StyledListItem = styled.li`
  border-bottom: 2px solid ${({ theme }) => theme.grey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  &:last-child {
    border: none;
  }
`;

const ListParagraph = styled.p`
  &.isBold {
    font-weight: ${({ theme }) => theme.bold};
  }
`;

const HouseSecondList: FC<{ currentFloor: number; house: any }> = ({
  currentFloor,
  house,
}) => (
  <>
    {currentFloor === 1 ? (
      <StyledList>
        <StyledListItem>
          <ListParagraph>00 Ilość pomieszczeń:</ListParagraph>
          <ListParagraph className="isBold">{house.roomsFloor}</ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>01 Wiatrołap:</ListParagraph>
          <ListParagraph className="isBold">{house.vestibule} m2</ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>02 Toaleta:</ListParagraph>
          <ListParagraph className="isBold">{house.toilet} m2</ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>03 Korytarz:</ListParagraph>
          <ListParagraph className="isBold">{house.hall} m2</ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>04 Schody:</ListParagraph>
          <ListParagraph className="isBold">{house.stairs} m2</ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>05 Pokój dzienny z aneksem:</ListParagraph>
          <ListParagraph className="isBold">
            {house.livingRoom} m2
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>06 Garaż:</ListParagraph>
          <ListParagraph className="isBold">
            {house.garageArea} m2
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>07 Całkowita powierzchnia parteru:</ListParagraph>
          <ListParagraph className="isBold">
            {house.totalFloor} m2
          </ListParagraph>
        </StyledListItem>
      </StyledList>
    ) : currentFloor === 2 ? (
      <StyledList>
        <StyledListItem>
          <ListParagraph>00 Ilość pomieszczeń:</ListParagraph>
          <ListParagraph className="isBold">
            {house.roomsFirstFloor}
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>01 Schody:</ListParagraph>
          <ListParagraph className="isBold">
            {house.firstFloorStairs} m2
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>02 Pokój:</ListParagraph>
          <ListParagraph className="isBold">
            {house.firstFloorRoom} m2
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>03 Korytarz:</ListParagraph>
          <ListParagraph className="isBold">
            {house.firstFloorHall} m2
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>04 Łazienka:</ListParagraph>
          <ListParagraph className="isBold">
            {house.firstFloorToilet} m2
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>05 Pralnia:</ListParagraph>
          <ListParagraph className="isBold">
            {house.firstFloorLaundry} m2
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>06 Pokój:</ListParagraph>
          <ListParagraph className="isBold">
            {house.firstFloorRoom2} m2
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>07 Pokój:</ListParagraph>
          <ListParagraph className="isBold">
            {house.firstFloorRoom3} m2
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>08 Całkowita powierzchnia piętra:</ListParagraph>
          <ListParagraph className="isBold">
            {house.totalFirstFloor} m2
          </ListParagraph>
        </StyledListItem>
      </StyledList>
    ) : (
      <StyledList>
        <StyledListItem>
          <ListParagraph>00 Ilość pomieszczeń:</ListParagraph>
          <ListParagraph className="isBold">{house.roomsLoft}</ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>01 Schody:</ListParagraph>
          <ListParagraph className="isBold">
            {house.loftStairs} m2
          </ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>02 Poddasze:</ListParagraph>
          <ListParagraph className="isBold">{house.loftArea} m2</ListParagraph>
        </StyledListItem>
        <StyledListItem>
          <ListParagraph>03 Całkowita powierzchnia poddasza:</ListParagraph>
          <ListParagraph className="isBold">{house.totalLoft} m2</ListParagraph>
        </StyledListItem>
      </StyledList>
    )}
  </>
);

export default HouseSecondList;
